.mobile-nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 100vw;
  background: var(--background-darker-color);
  
  transition-property: opacity, transform;
  transition-duration: 250ms, 500ms;
  transition-timing-function: ease-in-out, cubic-bezier(0.25, 1, 0.5, 1);
  will-change: opacity, transform;
  transform: translateX(-70%);
  opacity: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  z-index: 950;
}

.mobile-nav.open {
  transform: translateX(-100%);
  opacity: 1;
  pointer-events: auto;
}

.mobile-nav .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: 'DM Serif Display', serif;
  padding-top: var(--main-gutter);
}

.mobile-nav .menu .socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: var(--small-gutter);
}

.mobile-nav .menu .item {
  margin: 20px 0;
  opacity: 0.6;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
}

.mobile-nav .menu .socials .item {
  margin: 20px;
}

.mobile-nav .menu .socials .item svg {
  width: 30px;
  height: 30px;
}

.mobile-nav .menu .item.connect-btn,
.mobile-nav .menu .item.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  width: 260px;
  height: 70px;
  border-radius: var(--border-radius);
  opacity: 0.6;
}

.mobile-nav .menu .item.connect-btn:hover,
.mobile-nav .menu .item.logout-btn:hover {
  background: var(--main-color);
  color: white;
  opacity: 1;
}

@media (min-width: 992px) {
  .mobile-nav {
    display: none;
  }
}