.what-is-it .section.images {
  margin-top: calc(var(--main-gutter) * 3)
}

@media (max-width: 991px) {
  .what-is-it .section.images {
    /* margin-top: 0; */
    margin-top: calc(var(--main-gutter) * 1)
  }
}

.what-is-it .section.images .photo-thumb {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: zoom-in;
}

.what-is-it .section h3 {
  margin-top: var(--main-gutter);
}

.what-is-it .section h3.second {
  margin-top: var(--main-gutter);
}

@media (max-width: 991px) {
  .what-is-it {
    margin-top: var(--main-gutter);
  }
  .what-is-it .intro {
    margin-top: 0;
    /* margin-bottom: var(--main-gutter); */
  }
}