.video {
  position: relative;
}

.video .pause-btn {
  position: absolute;
  bottom: var(--small-gutter);
  left: var(--small-gutter);
  width: 30px;
  height: 30px;
  opacity: 0.6;
}

.video .pause-btn .anticon {
  font-size: 30px;
  line-height: 30px;
}

.video .pause-btn:hover {
  cursor: pointer;
  opacity: 1;
}