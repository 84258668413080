@media (max-width: 991px) {
  .banner {
    margin-top: var(--main-gutter);
  }
}

.banner .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: var(--small-gutter) 0;
}

.banner .step .number {
  font-size: 48px;
  line-height: 48px;
  color: var(--border-color);
  margin-right: 30px;
  width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
}

.banner .step .copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.banner .step .copy h3 {
  margin: 0 0 5px 0;
}

.banner .step .copy p {
  margin: 0;
}