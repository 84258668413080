.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
}

.modal .background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 0;
}

.modal .wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.modal .content {
  position: relative;
}

.modal .image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  background-color: var(--background-darker-color);
  transform: translate3d(0, 0, 0);
}
.modal .image-wrapper span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.modal .image-wrapper video {
  width: 100%;
  height: 100%;
}


.modal .close-btn {
  position: absolute;
  top: -45px;
  right: 0;
  width: 30px;
  height: 30px;
  opacity: 0.6;
}

.modal .close-btn .anticon {
  font-size: 30px;
  line-height: 30px;
}

.modal .close-btn:hover {
  cursor: pointer;
  opacity: 1;
}