.hero {
  margin-top: 60px;
  padding-top: 90px;
}

/* @media (max-width: 991px) {
  .hero .section.intro {
    margin-bottom: var(--main-gutter);
  }
} */

.hero .clickable {
  cursor: zoom-in;
}

@media (max-width: 991px) {
  .hero .grid-flower:nth-child(7),
  .hero .grid-flower:nth-child(8),
  .hero .grid-flower:nth-child(9) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hero .grid-flower:nth-child(7),
  .hero .grid-flower:nth-child(8),
  .hero .grid-flower:nth-child(9) {
    display: none;
  }
}

.hero .thumb {
  display: block;
  width: 100%;
  pointer-events: none;
}

.hero .video {
  display: block;
  width: 100%;
  height: 100%;
}

.hero h1 {
  /* margin-top: var(--main-gutter); */
  margin-top: 30px;
}

.hero h3 {
  margin: var(--main-gutter) 0;
  font-weight: 300;
}

.hero .button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: var(--main-gutter);
}

.hero .button-wrapper h3 {
  margin: 0;
  text-align: center;
}

.hero .wallet-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 70px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--main-color);
  box-sizing: border-box;
}

.hero .wallet-button h3 {
  color: var(--main-color);
  margin: 10px auto;
}

.hero .wallet-button:hover {
  background-color: var(--main-color);
}

.hero .wallet-button:hover h3 {
  color: white;
}

.hero .wallet-button h3 .anticon {
  margin-right: 10px;
}