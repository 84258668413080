.next .details {
  margin-top: calc(var(--main-gutter) * 3);
}

.next .thumbs {
  margin-top: calc(var(--main-gutter) * 1);
}

@media (max-width: 991px) {
  .next {
    margin-top: var(--main-gutter);
  }
  .next .thumbs {
    margin-top: 0;
  }
  .next .details {
    margin-top: 0;
  }
}

.next .video {
  display: block;
  width: 100%;
  height: 100%;
}

.next .species {
  border-radius: var(--border-radius);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.next .species .thumb {
  width: 100%;
  cursor: zoom-in;
}

.next .video {
  cursor: zoom-in;
}