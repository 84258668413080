.nft-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
}

.nft-item .thumb {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  /* border-radius: var(--border-radius); */
  background-color: rgba(0, 0, 0, 1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: zoom-in;
}

.nft-item .name {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  text-align: right;
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transform: translateY(100px);
  transition-property: transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}

.nft-item:hover .name {
  transform: translateY(0px);
}

.nft-item .name .anticon {
  margin-left: 5px;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .nft-item .name {
    display: none;
  }
}