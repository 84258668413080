.minter {
  margin-top: 90px;
  padding-top: 90px;
}

@media (max-width: 991px) {
  .minter {
    margin-top: 0px;
    padding-top: 0px;
  }
}

.minter .section-title {
  display: none;
}
@media (max-width: 991px) {
  .minter .section-title {
    display: block;
  }
}

.minter .input-wrapper {
  display: flex;
}

.minter .big-input {
  padding: var(--small-gutter) var(--small-gutter) var(--small-gutter) var(--main-gutter);
  border-radius: var(--border-radius);
  height: 70px;
  /* font-size: 18px !important; */
}

.minter .big-input input {
  font-size: 18px !important;
  letter-spacing: 1px;
}

.minter .big-input .affix {
  position: absolute;
  top: 10px;
  right: 10px;
}

.minter .big-input .random-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.minter .big-input .random-button .random-icon {
  width: 20px;
  height: 20px;
}
.minter .big-input .random-button .random-icon path {
  fill: var(--border-color);
  transition: fill 0.5s ease-in-out;
}
.minter .big-input .random-button:hover .random-icon path {
  fill: var(--main-color);
}

.minter .button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 260px; */
  width: 100%;
  height: 70px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  cursor: pointer;
  border: 1px solid var(--main-color);
  box-sizing: border-box;
  padding: 0 20px;
}

.minter .button.white {
  border: 1px solid white;
  background-color: white;
}

.minter .button h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  margin: 10px auto;
}

.minter .button.white h3 {
  color: black;
}

.minter .button.white:hover {
  border: 1px solid var(--main-color);
}
.minter .button:hover {
  background-color: var(--main-color);
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 20px -10px var(--main-color); */
  /* box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.8); */
  /* border-width: 3px; */
}

.minter .button:hover h3 {
  color: white;
  /* font-weight: bold; */
}

.minter .button h3 .anticon {
  margin-left: 10px;
}

.minter .big-input.disabled,
.minter .button.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.minter .ant-progress-circle-trail {
  stroke-width: 1px;
}

.minter .ant-progress-circle-path {
  stroke-width: 1px;
  stroke: var(--main-color) !important;
}

.minter .result .thumb {
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius);
  overflow: hidden;
  /* background-color: #000; */
  transform: translate3d(0, 0, 0);
}

.minter .result .thumb.loading {
  border: 1px solid var(--background-color);
  overflow: hidden;
}

.minter .result .thumb.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #f6f7f8; */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.02) 30%, rgba(255, 255, 255, 0) 60%);
  transform: translateX(-60%);

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  animation-name: placeholderAnimate;
}

.minter .status {
  color: var(--border-color)
}

.minter .status.minted {
  color: rgb(250, 66, 66);
}

.minter .status.success {
  color: white;
}

@keyframes placeholderAnimate {
  0% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(100%);
  }
}