.header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 70px;
}

.left,
.right {
  display: flex;
  height: 100%;
  padding: var(--small-gutter) var(--main-gutter);
}

.right {
  position: fixed;
  background-color: var(--background-darker-color);
  border-radius: 0 0 0 var(--border-radius);
  top: 0;
  right: 0;
  height: 70px;
  z-index: 800;
}

.header .menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-family: 'DM Serif Display', serif;
}

.header .menu .item {
  margin: 0 10px;
  opacity: 0.6;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  color: white;
}

.header .menu .item svg {
  width: 15px;
  height: 15px;
  fill: white;
}

.header .menu .item svg path {
  fill: white;
}

/* .header .menu .item.active {
  color: var(--main-color);
  opacity: 0.8;
} */

.header .menu .item:hover {
  opacity: 0.8;
}

.header .menu .item.selected {
  opacity: 1;
}

.header .left {
  align-items: center;
  position: absolute;
  margin: 0;
  padding: 0;
  top: var(--small-gutter);
  left: var(--main-gutter);
}

.header .left h1 {
  font-family: 'DM Serif Display', serif;
  color: white;
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 0;
}

.header .menu .item.connect-btn,
.header .menu .item.logout-btn {
  color: var(--main-color);
  border-radius: 15px;
  border: 1px solid var(--main-color);
  padding: 5px 15px;
  font-size: 12px;
  opacity: 0.6;
}

.header .menu .item.connect-btn:hover,
.header .menu .item.logout-btn:hover {
  background: var(--main-color);
  color: white;
  opacity: 1;
}

.header .mobile-menu {
  position: fixed;
  background-color: var(--background-darker-color);
  border-radius: var(--border-radius);
  top: var(--small-gutter);
  right: var(--small-gutter);
  height: 70px;
  width: 70px;
  z-index: 1000;
  display: none;
}

.header .mobile-menu .line {
  position: absolute;
  width: 30px;
  height: 4px;
  background: var(--main-color);
  left: 20px;
  transition-property: opacity, transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  will-change: opacity, transform;
  border-radius: 2px;
}

.header .mobile-menu .line.top {
  top: 24px;
}
.header .mobile-menu .line.middle-1,
.header .mobile-menu .line.middle-2 {
  top: 33px;
}
.header .mobile-menu .line.bottom {
  bottom: 24px;
}

.header .mobile-menu.open .line.top,
.header .mobile-menu.open .line.bottom {
  opacity: 0;
}

.header .mobile-menu.open .line.middle-1 {
  transform: rotate(45deg);
}

.header .mobile-menu.open .line.middle-2 {
  transform: rotate(-45deg);
}

@media (max-width: 991px) {
  .header .right {
    display: none;
  }

  .header .mobile-menu {
    display: flex;
  }
}

.header .counter {
  position: fixed;
  right: var(--small-gutter);
  top: 85px;
  background-color: var(--background-darker-color);
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  /* opacity: 0.8; */
  font-family: 'Poppins', sans-serif;
  color: var(--border-color);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  padding: 0 15px;
  font-size: 12px;
  z-index: 900;
}

@media (max-width: 991px) {
  .header .counter {
    /* top: 100px; */
    display: none;
  }
}