.benefits {
  /* margin-top: calc(var(--main-gutter) * 3) */
  margin-top: calc(var(--main-gutter) * 1)
}

/* @media (max-width: 991px) {
  .benefits {
    margin-top: 0;
  }
} */

.benefits .benefit {
  padding: var(--main-gutter);
  border-radius: var(--border-radius);  
  background-color: var(--background-darker-color);
}

@media (max-width: 991px) {
  .benefits .benefit {
    padding: var(--main-gutter) 20px;
  }
}

.benefits .benefit p {
  margin-bottom: 0;
}
