.download {
}

.download .section p {
  margin-bottom: 0;
}

.download .section p span {
  color: var(--main-color);
}

.download .ant-popover {
  
}

.ant-popover .ant-popover-title {
  display: none;
}

.ant-popover .ant-popover-inner-content {
  padding: 10px;
}

.download .big-input {
  padding: var(--small-gutter);
  border-radius: var(--border-radius);
  height: 70px;
  width: 100%;
  font-size: 18px !important;
  letter-spacing: 1px;
  text-align: center;
}

.download .big-input::-webkit-outer-spin-button,
.download .big-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.download .big-input[type=number] {
  -moz-appearance: textfield;
}

.download .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--main-color);
  box-sizing: border-box;
  padding: 0 20px;
}

.download .button h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  margin: 10px auto;
}

.download .button:hover {
  background-color: var(--main-color);
}

.download .button:hover h3 {
  color: white;
}

.download .button h3 .anticon {
  margin-left: 10px;
}

.download .big-input.disabled,
.download .button.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.download .status {
  color: var(--border-color)
}