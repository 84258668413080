.footer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  width: auto;
  min-width: 200px;
  height: 70px;
  z-index: 800;
  padding: 0 var(--main-gutter);
  background-color: var(--background-darker-color);
  border-radius: var(--border-radius) 0 0 0;
  bottom: 0;
  right: 0;
  height: 70px;
}

.footer h3 {
  margin: 0 var(--small-gutter) 0 0;
  line-height: 1em;
}

.footer .menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-family: 'DM Serif Display', serif;
}

.footer .menu .item {
  margin: 0 10px;
  opacity: 0.6;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  color: white;
}

.footer .menu .item svg {
  width: 15px;
  height: 15px;
}

.footer .menu .item svg path {
  fill: white;
}

.footer .menu .item:hover {
  opacity: 0.8;
}

.footer .mobile-only {
  display: none;
}

@media (max-width: 991px) {
  .footer h3 {
    display: none;
  }
  .footer .mobile-only {
    display: block;
  }
}