.App {
  position: relative;
  width: 100%;
  /* padding: 24px; */
  margin: auto;
  background: var(--background-color);
}

.App > .content {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 var(--main-gutter) 180px var(--main-gutter);
  margin: 0 auto;
}

.App > .ant-alert-banner {
  position: fixed;
  bottom: 0;
  top: auto;
  z-index: 801;
  width: 100%;
}

.App .flowers-grid {
  overflow: hidden;
  border-radius: var(--border-radius);
  /* margin-top: var(--main-gutter); */
  margin-top: calc(var(--main-gutter) * 2)
}

@media (max-width: 991px) {
  .App .flowers-grid {
    margin-top: 0;
  }
}

.App .flowers-grid .thumb {
  width: 100%;
  cursor: zoom-in;
}

.App .page-section {
  margin-bottom: calc(var(--main-gutter) * 2);
}
@media (max-width: 991px) {
  .App .page-section {
    margin-bottom: 0;
  }
}

.controls {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .App > .content {
    padding: 0 20px 180px 20px;
  }
}

h1 {
  font-family: 'DM Serif Display', serif;
  font-size: 42px;
  line-height: 54px;
}

h2 {
  font-family: 'DM Serif Display', serif;
  font-size: 32px;
  line-height: 48px;
}

h3 {
  font-size: 18px;
  line-height: 28px;
}

.section {
  border-radius: var(--border-radius);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  /* padding: 0; */
}

.section-title {
  margin-bottom: var(--small-gutter);
  margin-left: var(--small-gutter);
}

.section.dark {
  background-color: var(--background-darker-color);;
  padding: var(--main-gutter);
  /* padding: var(--small-gutter) var(--main-gutter); */
}

.section.border {
  border: 1px solid var(--border-color);
  /* padding: var(--small-gutter) var(--main-gutter); */
  padding: var(--main-gutter);
}

.ant-col.video video {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  margin-top: var(--main-gutter);
}

@media (max-width: 991px) {
  .section.dark {
    padding: var(--main-gutter) 20px;
  }

  .section.border {
    padding: var(--small-gutter) 20px;
  }
}