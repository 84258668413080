.favourites {
  margin: 0 10px 0 10px;
}

.favourites .slide {
  padding: 0 10px;
}

.favourites .sideways-title .section-title {
  transform-origin: top left;
  transform: rotate(90deg) translateY(-100%);
  align-self: flex-start;
  margin: 15px 0 0 0;
}

.favourites .mobile-title {
  display: none;
}

@media (max-width: 991px) {
  .favourites .sideways-title {
    display: none;
  }
  .favourites .mobile-title {
    display: block;
  }
}