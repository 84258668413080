:root {
  /* --main-color: rgb(4, 199, 78); */
  --main-color: #04c74e;
  --background-color: #212121;
  --background-darker-color: #141414;
  --border-radius: 35px;
  --main-gutter: 40px;
  --small-gutter: 15px;
  --border-color: #6f6c6c;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  background: var(--background-darker-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
